import { login, signup, updateAccount, updateInfo, logout, getInfo } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import router, { resetRouter } from '@/router'

const state = {
  token: getToken(),
  USERNAME: '',
  FIRST_NAME: '',
  LAST_NAME: '',
  ROLE_TYPE: '',
  IS_ALLOW_OCR: '',
  name: '',
  avatar: '',
  introduction: '',
  roles: []
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USERNAME: (state, USERNAME) => {
    state.USERNAME = USERNAME
  },
  SET_FIRST_NAME: (state, FIRST_NAME) => {
    state.FIRST_NAME = FIRST_NAME
  },
  SET_LAST_NAME: (state, LAST_NAME) => {
    state.LAST_NAME = LAST_NAME
  },
  SET_ROLE_TYPE: (state, ROLE_TYPE) => {
    state.ROLE_TYPE = ROLE_TYPE
  },
  SET_IS_ALLOW_OCR: (state, IS_ALLOW_OCR) => {
    state.IS_ALLOW_OCR = IS_ALLOW_OCR
  },

  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password }).then(response => {
        // console.log('login', response)
        const { data } = response
        commit('SET_TOKEN', data.token)
        // setToken(data.token)     // this will be done after change first password

        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  // user signup
  signup( userInfo) {
    const { username, password, first_name, last_name, role_type } = userInfo
    return new Promise((resolve, reject) => {
      signup({ USERNAME: username.trim(), PASSWORD: password, FIRST_NAME: first_name.trim(), LAST_NAME: last_name.trim(), ROLE_TYPE: role_type }).then(() => {
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token).then(response => {
        var { data } = response

        if (!data) {
          reject('Verification failed, please Login again.')
        }

        const { USERNAME, ROLE_TYPE, IS_ALLOW_OCR, 	FIRST_NAME, LAST_NAME } = data
        var roles = []
        if (ROLE_TYPE === 1) roles = ['admin']
        else if (ROLE_TYPE === 2) roles = ['scan operator']
        else if (ROLE_TYPE === 3) roles = ['doc query user']

        // const { roles, name, avatar, introduction } = data

        // roles must be a non-empty array
        if (!roles || roles.length <= 0) {
          // reject('getInfo: roles must be a non-null array!')
          reject('getInfo: role type is not correct!')
        }

        commit('SET_ROLES', roles)
        // commit('SET_NAME', name)
        // commit('SET_AVATAR', avatar)
        // commit('SET_INTRODUCTION', introduction)
        commit('SET_USERNAME', USERNAME)
        commit('SET_ROLE_TYPE', ROLE_TYPE)
        commit('SET_FIRST_NAME', FIRST_NAME)
        commit('SET_LAST_NAME', LAST_NAME)
        commit('SET_IS_ALLOW_OCR', IS_ALLOW_OCR)

        data.roles = roles
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // update info by admin
  updateAccount({ commit }, userInfo) {
    // const { ID, IS_ACTIVE, IS_ALLOW_OCR, PASSWORD, ROLE_TYPE } = userInfo
    return new Promise((resolve, reject) => {
      updateAccount(userInfo).then(response => {
        var { data } = response

        if (data.IS_ALLOW_OCR) commit('SET_IS_ALLOW_OCR', userInfo.IS_ALLOW_OCR)
        if (data.ROLE_TYPE) {
          var roles = []
          if (data.ROLE_TYPE === 1) roles = ['admin']
          else if (data.ROLE_TYPE === 2) roles = ['scan operator']
          else if (data.ROLE_TYPE === 3) roles = ['doc query user']
          if (!roles || roles.length <= 0) {
            // reject('getInfo: roles must be a non-null array!')
            reject('getInfo: role type is not correct!')
          }
          commit('SET_ROLES', roles)
          commit('SET_ROLE_TYPE', data.ROLE_TYPE)
          data.roles = roles
        }
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // update info
  updateInfo({ commit }, userInfo) {
    // const { NEW_USERNAME, OLD_PASSWORD, NEW_PASSWORD, FIRST_NAME, LAST_NAME } = userInfo
    if (userInfo.USERNAME_NEW) {
      userInfo.USERNAME_NEW = userInfo.USERNAME_NEW.trim()
    }
    console.log('updateInfo', userInfo)
    return new Promise((resolve, reject) => {
      updateInfo(userInfo).then(response => {
        const { data } = response

        if (!data) {
          reject('Verification failed, please Login again.')
        }
        if (userInfo.USERNAME_NEW) {
          commit('SET_USERNAME', userInfo.USERNAME_NEW)
        }
        if (userInfo.FIRST_NAME) {
          commit('SET_FIRST_NAME', userInfo.FIRST_NAME)
        }
        if (userInfo.LAST_NAME) {
          commit('SET_LAST_NAME', userInfo.LAST_NAME)
        }
        commit('SET_TOKEN', data.token)
        setToken(data.token)

        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        commit('SET_ROLE_TYPE', '')
        removeToken()
        resetRouter()

        // reset visited views and cached views
        // to fixed https://github.com/vue-element-admin/issues/2485
        dispatch('tagsView/delAllViews', null, { root: true })

        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      commit('SET_ROLE_TYPE', '')
      removeToken()
      resolve()
    })
  },

  // dynamically modify permissions
  async changeRoles({ dispatch }) {
    // const token = role + '-token'

    // commit('SET_TOKEN', token)
    // setToken(token)

    const { roles } = await dispatch('getInfo')

    resetRouter()

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
    // dynamically add accessible routes
    router.addRoutes(accessRoutes)

    // reset visited views and cached views
    dispatch('tagsView/delAllViews', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
