import { createRouter, createWebHistory } from 'vue-router';


/* Layout */
import Layout from '@/layout'


export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index')
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/auth-redirect',
    component: () => import('@/views/login/auth-redirect'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/error-page/401'),
    hidden: true
  },
  {
    path: '/printgraphics',
    name: 'printgraphics',
    component: () => import('@/views/doc_query/printView')
  },
  {
    path: '/printbarcodes',
    name: 'printbarcodes',
    component: () => import('@/views/print_barcode/printView')
  }
]

export const asyncRoutes = [
  // 404 page must be placed at the end !!!
  { path: '/', redirect: '/work_list/index', hidden: true, meta: { roles: ['admin', 'scan operator'] } },
  {
    path: '/work_list',
    component: Layout,
    children: [
      {
        path: 'index',
        component: () => import('@/views/work_list/index'),
        name: '工作站',
        meta: { roles: ['admin', 'scan operator'], title: '工作站', affix: true }
      }
    ]
  },
  { path: '/', redirect: '/docquery/index', hidden: true, meta: { roles: ['doc query user'] } },
  {
    path: '/scan',
    component: Layout,
    children: [
      {
        path: 'index',
        component: () => import('@/views/batchscan/scan'),
        name: '病歷管理',
        meta: { roles: ['admin', 'scan operator'], title: '病歷管理', icon: 'TakeawayBox', affix: false }
      }
    ]
  },
  {
    path: '/print_barcode',
    component: Layout,
    children: [
      {
        path: 'index',
        component: () => import('@/views/print_barcode/index'),
        name: '條碼生成器',
        meta: { roles: ['admin', 'scan operator'], title: '條碼生成器', icon: 'Cpu', affix: false }
      }
    ]
  },
  {
    path: '/users',
    component: Layout,
    children: [
      {
        path: 'index',
        component: () => import('@/views/users/index'),
        name: '帳號管理',
        meta: { roles: ['admin'], title: '帳號管理', icon: 'User', affix: false,}
      }
    ]
  },
  {
    path: '/action_record',
    component: Layout,
    children: [
      {
        path: 'index',
        component: () => import('@/views/action_record/index'),
        name: '使用紀錄',
        meta: { roles: ['admin'], title: '使用紀錄', icon: 'Collection', affix: false }
      }
    ]
  },
  
 
  {
    path: '/docquery',
    component: Layout,
    children: [
      {
        path: 'index',
        component: () => import('@/views/batchscan/scan'),
        name: '文件查询与调阅',
        meta: { roles: ['doc query user'], title: '文件查询与调阅', icon: 'TakeawayBox', affix: false }
      }
    ]
  },
  {
    path: '/profile',
    component: Layout,
    children: [
      {
        path: 'index',
        component: () => import('@/views/profile/index'),
        name: '個人資訊',
        meta: { title: '個人資訊', icon: 'User', affix: false }
      }
    ]
  },
  {
    path: '/About',
    component: Layout,
    children: [
      {
        path: 'index',
        component: () => import('@/components/About.vue'),
        name: '系統',
        meta: { title: '系統', affix: false }
      }
    ]
  }

]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior: () => ({ top: 0 }),
  routes: constantRoutes
});

export function resetRouter() {
  const newRouter = createRouter({
    history: createWebHistory(),
    scrollBehavior: () => ({ top: 0 }),
    routes: constantRoutes
  });

  router.matcher = newRouter.matcher; // Reset router
}

export default router;
