<template>
  <div>
    <div class="navbar">
      <hamburger
          id="hamburger-container"
          :is-active="sidebar.opened"
          class="hamburger-container"
          @toggleClick="toggleSideBar"
      />

      <breadcrumb
          id="breadcrumb-container"
          class="breadcrumb-container"
          hidden="true"
      />

      <div class="right-menu">
        <template v-if="device !== 'mobile'"></template>

        <el-dropdown class="avatar-container right-menu-item hover-effect">
          <div class="avatar-wrapper">
            <!--src="https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif"-->
            <img src="@/assets/logo/smhc.svg"
                 class="user-avatar"/>
            <div class="user-name">{{ FIRST_NAME + " " + LAST_NAME }}</div>
            <el-icon class="el-icon-caret-bottom">
              <CaretBottom/>
            </el-icon>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <router-link to="/profile/index">
                <el-dropdown-item>個人中心</el-dropdown-item>
              </router-link>
              <router-link to="/About/index">
                <el-dropdown-item>關於</el-dropdown-item>
              </router-link>
              <el-dropdown-item divided @click="logout">
                <span style="display: block">登出帳號</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Breadcrumb from "@/components/Breadcrumb";
import Hamburger from "@/components/Hamburger";

export default {
  name: "scanNavbar",
  components: {
    Breadcrumb,
    Hamburger,
  },
  computed: {
    ...mapGetters([
      "sidebar",
      "avatar",
      "device",
      "name",
      "FIRST_NAME",
      "LAST_NAME",
    ]),
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    logout() {
      this.$confirm("確定要登出系統嗎?", "登出帳號", {
        confirmButtonText: "登出",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$store.dispatch("user/logout").then(() => {
          // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
          this.$router.push(`/login`);
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #f7f9fc;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  margin-right: 10px;

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .user-name {
          float: right;
          cursor: pointer;
          height: 40px;
          line-height: 40px;
          margin-left: 5px;
          font-size: medium;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
