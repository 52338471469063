<template>
  <component :is="component" v-bind="componentProps">
    <slot />
  </component>
</template>

<script>
import { isExternal } from '@/utils/validate'
import { defineComponent } from 'vue'

export default defineComponent({
  name:'scanLink',
  props: {
    to: {
      type: String,
      required: true
    }
  },
  computed: {
    component() {
      return isExternal(this.to) ? 'a' : 'router-link'
    },
    componentProps() {
      if (isExternal(this.to)) {
        return {
          href: this.to,
          target: '_blank',
          rel: 'noopener'
        }
      } else {
        return {
          to: this.to
        }
      }
    }
  }
})
</script>