import { deepClone } from '@/utils/index'

const state = {
  worklistHeaders: []
}

const mutations = {
  SET_WORKLIST_HEADERS: (state, latestHeaders) => {
    state.worklistHeaders = deepClone(latestHeaders);
  }
}

const actions = {
  
  setWorklistHeaders({commit}, latestHeaders) {
    commit('SET_WORKLIST_HEADERS', latestHeaders)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
