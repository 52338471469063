import { createApp } from 'vue'

import App from './App.vue'
import store from './store'
import router from './router'

import './icons' // icon
import './permission' // permission control
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
//  import * as filters from './filters' // global filters
import * as utils from './utils' // global method
// import waves from '@/directive/waves' //  button waves
import permission from '@/directive/permission' //  button permission

import '@/styles/element-variables.scss'
import '@/styles/index.scss' // global css
import axios from 'axios'
// import installElementPlus from './plugins/element'
// import VueCryptojs from 'vue-cryptojs'
// import Tinymce from '@/components/Tinymce/index.vue'

const app = createApp(App)


// Set global directives and components
// app.use(waves)
app.use(permission)
// app.use(VueCryptojs)

// Set global methods
Object.keys(utils).forEach(key => {
  app.config.globalProperties[key] = utils[key]
})

// Set global filters
// Object.keys(filters).forEach(key => {
//   app.config.globalProperties.$filters[key] = filters[key]
// })
app.use(ElementPlus)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.config.productionTip = false
app.config.globalProperties.$axios = axios

app.use(store)
app.use(router)



app.mount('#app')