<script>
import { defineComponent, h } from 'vue';

export default defineComponent({
  name: 'MenuItem',
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  render() {
    const vnodes = [];

    if (this.icon) {
      vnodes.push(h('SvgIcon', { 'icon-class': this.icon }));
    }

    if (this.title) {
      vnodes.push(h('template', { slot: 'title' }, h('span', this.title)));
    }

    return vnodes;
  }
});
</script>