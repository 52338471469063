import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/api/user/login',
    method: 'post',
    data
  })
}

export function signup(data) {
  return request({
    url: '/api/user/signup',
    method: 'post',
    data
  })
}

// admin permission  USERNAME, IS_ACTIVE, IS_ALLOW_OCR, PASSWORD, ROLE_TYPE
export function updateAccount(data) {
  return request({
    url: '/api/user/updateAccount',
    method: 'put',
    data
  })
}

// user profile  USERNAME_NEW, PASSWORD_CUR, PASSWORD_NEW, FIRST_NAME, LAST_NAME
export function updateInfo(data) {
  return request({
    url: '/api/user/updateInfo',
    method: 'put',
    data
  })
}

export function getInfo(token) {
  return request({
    url: '/api/user/info',
    method: 'get',
    params: { token }
  })
}

export function getUser(query) {
  return request({
    url: '/api/user/getUser',
    method: 'get',
    params: query
  })
}

export function countUser(query) {
  return request({
    url: '/api/user/countUser',
    method: 'get',
    params: query
  })
}

export function deleteUser(id) {
  return request({
    url: '/api/user/deleteUser/' + id,
    method: 'delete'
  })
}

export function logout() {
  return request({
    url: '/api/user/logout',
    method: 'post'
  })
}
