<template>
  <div v-if="!item.hidden">
    <template
      v-if="
        hasOneShowingChild(item.children, item) &&
        (!onlyOneChild.children || onlyOneChild.noShowingChildren) &&
        !item.alwaysShow
      "
    >
      <app-link
        v-if="onlyOneChild.meta && !onlyOneChild.meta.affix"
        :to="resolvePath(onlyOneChild.path)"
      >
        <el-menu-item 
          style="display: absolute"
          :index="resolvePath(onlyOneChild.path)"
          :class="{ 'submenu-title-noDropdown': !isNest }"
        >
          <div v-if="isCollapse" class="sidebar-body-collapse justify-center">
            <div
              v-if="onlyOneChild.meta.svgSRC"
              class="sidebar-item-border-collapse svg-container"
            >
              <el-icon size="80">
                <component :is="onlyOneChild.meta.icon" />
              </el-icon>
            </div>
            <div v-else class="sidebar-item-border-collapse svg-container">
              <el-icon size="80">
                <component :is="onlyOneChild.meta.icon" />
              </el-icon>
            </div>
          </div>
          <div v-else class="sidebar-body justify-center">
            <div
              v-if="onlyOneChild.meta.svgSRC"
              class="sidebar-item-border svg-container"
            >
              <el-icon size="80">
                <component :is="onlyOneChild.meta.icon" />
              </el-icon>
            </div>
            <div v-else class="sidebar-item-border">
              <el-icon size="80">
                <component :is="onlyOneChild.meta.icon" />
              </el-icon>
              {{ onlyOneChild.meta.title }}
            </div>
            <div class="divide-line"></div>
          </div>
        </el-menu-item>
      </app-link>
    </template>



    <el-sub-menu v-else ref="subMenu" :index="resolvePath(item.path)" popper-append-to-body>
      <template #title>
        <item v-if="item.meta" :icon="item.meta && item.meta.icon" :title="item.meta.title" />
      </template>
      <sidebar-item
        v-for="child in item.children"
        :key="child.path"
        :is-nest="true"
        :item="child"
        :base-path="resolvePath(child.path)"
        class="nest-menu"
      />
    </el-sub-menu>
  </div>
</template>

<style>
.sidebar-body {
  margin-bottom: 8px;
  color: #d6dce5 !important;
  font-size: 18px;
  font-weight: bold;
  border-top: 4px solid #d6dce5 !important;
  border-bottom: 4px solid #d6dce5 !important;
  border-right: 3px solid #d6dce5 !important;
  min-height: inherit;
  height: inherit;
}

.sidebar-body-collapse {
  margin-bottom: 0px;
  color: #d6dce5 !important;
  font-size: 18px;
  font-weight: bold;
  border-top: 4px solid #d6dce5 !important;
  border-bottom: 4px solid #d6dce5 !important;
  border-right: 3px solid #d6dce5 !important;
  min-height: inherit;
  height: inherit;
}

.sidebar-icon {
  font-size: 36px !important;
  color: white !important;
  padding-right: 4px;
}

.sidebar-svg-icon {
  font-size: 36px !important;
  color: white !important;
  padding-right: 4px;
}

.sidebar-item-border {
  padding: 0 24px 0 24px;
  min-height: inherit;
  display: flex;
  align-items: center;
}

.sidebar-item-border-collapse {
  padding: 0 5px 0 5px;
  text-align: center;
  min-height: inherit;
  align-items: center;
  justify-content: center;
  display: flex;
}

.el-menu-item {
  padding: 0 !important;
  min-height: 80px;
}

.divide-line {
  margin: 0 24px 0 24px;
  border-bottom: 5px solid #d6dce5 !important;
  border-radius: 3px;
  margin-top: 60px;
}
</style>

<script>
import { mapGetters } from "vuex";
import path from "path";
import { isExternal } from "@/utils/validate";
import Item from "./Item";
import AppLink from "./Link";
import FixiOSBug from "./FixiOSBug";

export default {
  name: "SidebarItem",
  components: { Item, AppLink },
  mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true,
    },
    isNest: {
      type: Boolean,
      default: false,
    },
    basePath: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters(["sidebar"]),
    isCollapse() {
      return !this.sidebar.opened;
    },
  },
  data() {
    // To fix https://github.com/PanJiaChen/vue-admin-template/issues/237
    // TODO: refactor with render function
    this.onlyOneChild = null;
    return {};
  },
  methods: {
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter((item) => {
        if (item.hidden) {
          return false;
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item;
          return true;
        }
      });

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true;
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: "", noShowingChildren: true };
        return true;
      }

      return false;
    },
    resolvePath(routePath) {
      if (isExternal(routePath)) {
        return routePath;
      }
      if (isExternal(this.basePath)) {
        return this.basePath;
      }
      return path.resolve(this.basePath, routePath);
    },
  },
};
</script>
